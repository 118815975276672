// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700&display=swap&subset=cyrillic,latin-ext');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
//Flag icons
@import '~flag-icon-css/sass/flag-icon';
//bootstrap-select
@import '~bootstrap-select/sass/bootstrap-select.scss';
//font-awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
//datatables

@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
//tempus-dominus
@import '~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4';
